import {Component, OnDestroy, OnInit} from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  public images:Array<string> = [
    "1.jpg",
    "2.jpg",
    "3.png",
    "4.png",
    "6.jpeg",
    "7.jpg",
    "8.jpg",
    "9.jpg",
    "10.jpg"
  ];
  public selected:number;
  public interval:any;

  constructor() { }

  ngOnInit() {
    this.selected = 0;
    this.interval = setInterval(() => {
      this.selected = (this.images[this.selected + 1] ? this.selected + 1 : 0);
    }, 1000);
  }

  ngOnDestroy(): void {
  }
}
