import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public nav:Array<any>;
  constructor() { }

  ngOnInit() {
    this.nav = [
      {label: "Héritage", route: "/heritage"},
      {label: "Collections", route: "/collection"},
      {label: "Contact", route: "/contact"},
    ]
  }

}
