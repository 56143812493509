import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public name_part_1:Array<string>;
  public name_part_2:Array<string>;

  constructor() { }

  ngOnInit() {
    this.name_part_1 = "gustave".split("");
    this.name_part_2 = "keller".split("");
  }

}
