import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { ImagesComponent } from './images/images.component';
import { HomeComponent } from './pages/home/home.component';
import { HeritageComponent } from './pages/heritage/heritage.component';
import { CollectionComponent } from './pages/collection/collection.component';
import { ContactComponent } from './pages/contact/contact.component';
import {RouterModule} from "@angular/router";
import { FooterComponent } from './footer/footer.component';

let routes = [{
  path: "",
  component: HomeComponent
}, {
  path: "heritage",
  component: HeritageComponent
}, {
  path: "collection",
  component: CollectionComponent
}, {
  path: "contact",
  component: ContactComponent
}, {
  path: "**",
  redirectTo: ""
}];

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ImagesComponent,
    HomeComponent,
    HeritageComponent,
    CollectionComponent,
    ContactComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes)
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
