import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public show_text:boolean;

  ngOnInit(): void {
    let vh = window.innerHeight * 0.01;
    document.getElementsByTagName("body")[0].style.setProperty("--vh", `${vh}px`);
  }
}
