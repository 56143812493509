import {Component, OnDestroy, OnInit} from '@angular/core';

@Component({
  selector: 'app-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.scss']
})
export class ImagesComponent implements OnInit, OnDestroy {
  public circles:HTMLCollectionOf<any>;
  public interval:any;

  constructor() { }

  ngOnInit() {
    this.circles = document.getElementsByClassName('circle');

    let old_id = 0;
    this.circles[old_id].style.opacity = 1;
    this.interval = setInterval(() => {
      let circles_id = [0,2,7,3,4,6,8,1,9,5];
      if (window.innerWidth < 1290)
        circles_id = [0,7,1,5,3,4,8,2,9];
      if (window.innerWidth < 736)
        circles_id = [3,0,7,1,4,8];
      if (window.innerWidth < 480)
        circles_id = [0,3,7,4];

      let old_pos = circles_id.indexOf(old_id);
      this.circles[old_id].style.opacity = 0;
      old_id = old_pos == -1 ? circles_id[Math.floor(Math.random() * circles_id.length)] : (old_pos + 1 >= circles_id.length ? 0 : circles_id[old_pos + 1]);
      this.circles[old_id].style.opacity = 1;

    }, 3000);
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

}
